import imagesLoaded from 'imagesloaded'
import EventEmitter from '../Classes/EventEmitter'
// import gsap from "gsap";

export default class Preloader extends EventEmitter {
  constructor() {
    super()
    this.DOM = { el: document.querySelector('.pre_loader') }
    this.DOM.images = [...document.querySelectorAll('img')]
    this.DOM.quotes = [...this.DOM.el.querySelectorAll('.preloader__quote')]
    this.DOM.numberText = this.DOM.el.querySelector('.preloader__number__text')
    this.DOM.iframe = document.querySelector('iframe')
  //  this.length = 0
    this.init()
  
  }

  init() {
    this.chooseQuote()
  }

  chooseQuote() {
        // loop through illustrations and set the illustration as a localstorage item
        if (this.DOM.quotes) {
          if (localStorage.quote) {
            let number = localStorage.quote
            number < this.DOM.quotes.length - 1 ? number++ : (number = 0)
            localStorage.setItem("quote", number)
          } else {
            localStorage.setItem("quote", 0)
          }

          // set quote visible
          this.DOM.quotes[localStorage.quote].classList.add("js-visible")
        }

        this.imageLoader()
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on('progress', (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  onImagesLoaded(instance, img) {

      if (this.DOM.iframe) {
        setTimeout(() => {
          this.emit('completed', 'yes')
        }, 2000)
      } else {
        this.emit('completed', 'yes')
      }
    
  }

  destroy() {
    this.DOM.el.classList.add('hidden__preloader')
    setTimeout(() => {
    this.DOM.el.parentNode.removeChild(this.DOM.el)
    }, 2000)
  }
}
