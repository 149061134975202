import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

export default class TextProfiles  {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.inner = this.DOM.el.querySelector('.TextProfiles__inner')
        this.DOM.image = this.DOM.el.querySelector('.TextProfiles__right');
        this.DOM.columns = [...this.DOM.el.querySelectorAll('.tr-column__inner')]
        this.DOM.singleImage = this.DOM.el.querySelector('.TextProfiles__image-item')
        this.init()
    }

    init() {
        this.animate()
        // Parallax(this.DOM.image)
        // gsap.set(this.DOM.image, { scale: 0.5, opacity: 0, y:250 })
        // gsap.to(this.DOM.image, { scale: 1, opacity: 1, y:0, duration: 2, ease: 'power4.out' }, 0)
    }

    animate() {
        // set second column up 50 percent
        gsap.set(this.DOM.columns[1], {yPercent: -50})
        this.DOM.columns.forEach((column, i) => { 
            let pos
            i === 1 ?  pos = 0 : pos = -50
            gsap.to(column, {
                scrollTrigger: {
                    trigger: this.DOM.inner,
                    start: "bottom bottom",
                    end: 'bottom center',
                    scrub: 1 + (i * 0.3),
                  //  markers: true
                },
                yPercent: pos,
                duration: 1,
            })
        })
    }

    onResize() {
        this.init()
    }
}