import { gsap, Flip } from 'gsap/all'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
gsap.registerPlugin(Flip, ScrollSmoother)

// https://tympanus.net/Development/GridZoom/

// https://codepen.io/GreenSock/pen/LYZYPpE

//TODO

// // 1. MAKE SURE PHOTOS RETURN TO ORIGNAL POSITIONS
// 2. MAKE MINI MAP POP UP / DOWN
// 3. MATCH MINIMAP PHOTOS TO IMAGES IN GRID
// 4. ANIMATE IN NEW TEXT CONTENT
// 5. ANIMATE OUT TEXT CONTENT
// 6. Stop scrolling when open
// // 6. Hover effect codrops
// 7. on image selection minimize other grid items

export default class MeetTheTeam {
  constructor({ scroll, container, screenSize, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.screenSize = screenSize
    this.DOM.items = [...this.DOM.el.querySelectorAll('.js-team')]

    this.DOM.teamViewer = this.DOM.el.querySelector('.js-fixed')
    this.DOM.teamViewer.text =
      this.DOM.teamViewer.querySelector('.teamview__text')
    this.DOM.teamViewer.wrapper = this.DOM.el.querySelector(
      '.teamviewer__wrapper'
    )
    this.DOM.teamViewer.miniMap = this.DOM.teamViewer.wrapper.querySelector(
      '.teamviewer__mini-map'
    )
    this.DOM.teamViewer.miniImages = [
      ...this.DOM.teamViewer.wrapper.querySelectorAll('.mini-map__image')
    ]
    this.DOM.teamViewer.image = this.DOM.teamViewer.querySelector('.js-image')
    this.DOM.teamViewer.itemclose =
      this.DOM.el.querySelector('.teamviewer__close')

    this.DOM.teamViewer.name = this.DOM.teamViewer.querySelector('.team_title')
    this.DOM.teamViewer.position =
      this.DOM.teamViewer.querySelector('.team_position')
    this.DOM.teamViewer.bio = this.DOM.teamViewer.querySelector('.team_bio')
    this.DOM.teamViewer.button =
      this.DOM.teamViewer.querySelector('.js-btn.team')
    this.DOM.teamViewer.emailButton =
      this.DOM.teamViewer.querySelector('.js-btn.email')

    this.lastClickedCard
    this.init()
  }

  create() {
    // Pin Teamviewer / MINIMAP to page
    const oldParent = document.getElementById('teamviewer__reparent')
    const newParent = document.getElementById('newparent__teamviewer')

    newParent.append(...oldParent.childNodes)

    gsap.set(
      [
        this.DOM.teamViewer.miniMap,
        this.DOM.teamViewer.name,
        this.DOM.teamViewer.position
      ],
      { yPercent: 100 }
    )
    gsap.set([this.DOM.teamViewer.bio, this.DOM.teamViewer.button], {
      yPercent: 10,
      autoAlpha: 0
    })
    gsap.set([this.DOM.teamViewer.bio, this.DOM.teamViewer.emailButton], {
      yPercent: 10,
      autoAlpha: 0
    })
    gsap.set(this.DOM.teamViewer.text, { autoAlpha: 0 })
  }

  putBack(image) {
    let state = Flip.getState(image)

    this.lastClickedCard.appendChild(image)

    Flip.from(state, {
      duration: 0.6,
      ease: 'expo.out',
      absolute: true,
      onStart: () => {
        gsap
          .timeline()
          .to([this.DOM.teamViewer.name, this.DOM.teamViewer.position], {
            yPercent: 100,
            ease: 'expo.out',
            duration: 0.3,
            stagger: 0.15
          })
          .to(
            [this.DOM.teamViewer.button],
            {
              yPercent: 10,
              autoAlpha: 0,
              ease: 'expo.out',
              duration: 0.3,
              stagger: 0.15
            },
            0
          )
          .to(
            [this.DOM.teamViewer.emailButton],
            {
              yPercent: 10,
              autoAlpha: 0,
              ease: 'expo.out',
              duration: 0.3,
              stagger: 0.15
            },
            0
          )
          .to(
            [this.DOM.teamViewer.bio],
            {
              yPercent: 10,
              autoAlpha: 0,
              ease: 'expo.out',
              duration: 0.3,
              stagger: 0.15
            },
            0
          )
      }
    })

    this.lastClickedCard = null
  }

  showTeam(event) {
    // console.log(event.currentTarget)
    document.querySelector('body').style.overflow = 'hidden'
    let image = this.DOM.teamViewer.image.querySelector('img')

    // add active if not already
    if (!this.DOM.teamViewer.wrapper.classList.contains('js-active')) {
      this.DOM.teamViewer.wrapper.classList.add('js-active')
      gsap
        .timeline()
        .to([this.DOM.teamViewer.text], {
          autoAlpha: 1,
          ease: 'expo.out',
          duration: 0.4
        })
        .to(
          [this.DOM.teamViewer.miniMap],
          { yPercent: 0, ease: 'expo.out' },
          '<50%'
        )
    }

    // put card back
    if (this.lastClickedCard) this.putBack(image)

    // grab all of the team member Articles info and put this into an object we can then put into teamviewer
    const member = event.currentTarget
    const info = member.querySelector('.js-info')
    this.image = member.querySelector('img')

    //add active to minimap selection

    this.DOM.teamViewer.miniImages.forEach((image) => {
      image.classList.remove('js-active')

      if (image.id === member.id) {
        image.classList.add('js-active')
      }
    })

    this.currentMemberInfo = {
      // image: member.querySelector('.js-image img').src,
      name: info.dataset.name,
      position: info.dataset.position,
      bio: info.dataset.bio,
      emailLabel: info.dataset.emaillabel,
      emailAddress: info.dataset.emailaddress
    }

    // ammend info
    this.DOM.teamViewer.name.textContent = this.currentMemberInfo.name
    this.DOM.teamViewer.position.textContent = this.currentMemberInfo.position
    this.DOM.teamViewer.bio.textContent = this.currentMemberInfo.bio

    this.DOM.teamViewer.emailAddress = this.currentMemberInfo.emailAddress
    this.DOM.teamViewer.emailLabel = this.currentMemberInfo.emailLabel

    if (this.currentMemberInfo.emailAddress) {
      console.log(this.DOM.teamViewer.emailButton)
      this.DOM.teamViewer.emailButton.style.display = 'inline-block'
      this.DOM.teamViewer.emailButton.setAttribute(
        'href',
        `mailto:${this.currentMemberInfo.emailAddress}`
      )
      this.DOM.teamViewer.emailButton.textContent =
        this.currentMemberInfo.emailLabel
    } else {
      this.DOM.teamViewer.emailButton.style.display = 'none'
    }

    // console.log(this.DOM.teamViewer.emailAddress, this.DOM.teamViewer.emailAddress);
    console.log(
      this.currentMemberInfo.emailLabel,
      this.currentMemberInfo.emailAddress
    )

    //show card
    this.openCard(event.currentTarget, this.image)
  }

  close() {
    document.querySelector('body').style.overflow = 'visible'
    let image = this.DOM.teamViewer.image.querySelector('img')

    gsap
      .timeline({
        onStart: () => {
          this.DOM.teamViewer.wrapper.classList.remove('js-active')
        }
      })
      .to([this.DOM.teamViewer.text], {
        autoAlpha: 0,
        ease: 'expo.out',
        duration: 0.4
      })
      .to(
        this.DOM.teamViewer.miniMap,
        { yPercent: 100, ease: 'expo.out' },
        '<50%'
      )

    //allow scrollinh
    ScrollSmoother.get().paused(false)

    if (!this.lastClickedCard) return

    this.putBack(image)
  }

  openCard(target, image) {
    //animate from previous state
    let state = Flip.getState(image)

    // should be target: grid team card, image: teamviewer image
    //console.log(target, image)

    // move the div to the new parent
    this.DOM.teamViewer.image.appendChild(image)

    // animate between the states
    //  Flip.fit(this.image, this.DOM.teamViewer.image, {scale: true});
    Flip.from(state, {
      duration: 0.6,
      ease: 'expo.out',
      absolute: true,
      onComplete: () => {
        gsap.to(
          [
            this.DOM.teamViewer.name,
            this.DOM.teamViewer.position,
            this.DOM.teamViewer.bio,
            this.DOM.teamViewer.button,
            this.DOM.teamViewer.emailButton
          ],
          {
            yPercent: 0,
            autoAlpha: 1,
            ease: 'expo.out',
            duration: 0.6,
            stagger: 0.15
          }
        )
      }
    })

    // stop scrolling
    ScrollSmoother.get().paused(true)

    this.lastClickedCard = target
  }

  miniImageClick(event) {
    let currentImage
    let currentCard
    let currentMemberInfo = {}
    let currentData = event.currentTarget.querySelector('img')
    let teamviewImage = this.DOM.teamViewer.image.querySelector('img')

    // find original image in DOM

    this.DOM.items.forEach((member) => {
      if (member.id === event.currentTarget.id) {
        currentCard = member
        currentImage = member.querySelector('img')

        currentMemberInfo = {
          // image: member.querySelector('.js-image img').src,
          name: currentData.dataset.name,
          position: currentData.dataset.position,
          bio: currentData.dataset.bio,
          emailLabel: currentData.dataset.emailLabel,
          emailAddress: currentData.dataset.emailAddress
        }
      }
    })

    // IF teamviewer.img id matches the new current image dont do anything
    if (teamviewImage.dataset.photo === event.currentTarget.id) return

    // add active to current mini-map selection
    this.DOM.teamViewer.miniImages.forEach((image) => {
      image.classList.remove('js-active')
    })
    event.currentTarget.classList.add('js-active')

    // PUT AWAY CURRENT IMAGE
    if (this.lastClickedCard) this.putBack(teamviewImage)

    // ANIMATE IN!!!

    //animate from previous state
    let state = Flip.getState(currentImage)

    // add new image
    this.DOM.teamViewer.image.appendChild(currentImage)

    Flip.from(state, {
      duration: 0.8,
      ease: 'expo.out',
      absolute: true,
      onComplete: () => {
        // change content to new persons
        this.DOM.teamViewer.name.textContent = currentMemberInfo.name
        this.DOM.teamViewer.position.textContent = currentMemberInfo.position
        this.DOM.teamViewer.bio.textContent = currentMemberInfo.bio

        if (currentMemberInfo.emailAddress) {
          console.log(this.DOM.teamViewer.emailAddress)
          this.DOM.teamViewer.emailButton.style.display = 'inline-block'
          this.DOM.teamViewer.emailButton.setAttribute(
            'href',
            `mailto:${currentMemberInfo.emailAddress}`
          )
          this.DOM.teamViewer.emailButton.textContent =
            currentMemberInfo.emailLabel
        } else {
          this.DOM.teamViewer.emailButton.style.display = 'none'
        }

        gsap.to(
          [
            this.DOM.teamViewer.name,
            this.DOM.teamViewer.position,
            this.DOM.teamViewer.bio,
            this.DOM.teamViewer.button,
            this.DOM.teamViewer.emailButton
          ],
          {
            yPercent: 0,
            autoAlpha: 1,
            ease: 'expo.out',
            duration: 0.6,
            stagger: 0.15
          }
        )
      }
    })

    this.lastClickedCard = currentCard

    // OLD CARD this.lastClickedCard
    // NEW CARD currentImage
  }

  addEventListeners() {
    this.DOM.items.forEach((item) => {
      item.addEventListener('click', this.showTeam.bind(this))
    })

    this.DOM.teamViewer.miniImages.forEach((image) => {
      image.addEventListener('click', this.miniImageClick.bind(this))
    })

    this.DOM.teamViewer.itemclose.addEventListener(
      'click',
      this.close.bind(this)
    )
  }

  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }

  init() {
    this.create()
    this.addEventListeners()
    //   this.onResize()
  }
}
