import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)

export default class Button  {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.Buttons = [...this.DOM.el.querySelectorAll('.btn-main')];
        this.init()
    }

    init() {
        gsap.to(Button, { scale: 1.5, duration: 2, ease: 'power4.out' }, 0)
    }

    onResize() {
        this.DOM.Buttons.forEach(Button => {
            this.init()
        })
    }
}

