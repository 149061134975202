import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, DrawSVGPlugin, SplitText } from 'gsap/all'
gsap.registerPlugin(DrawSVGPlugin, SplitText)

export default class HeroFullWidth {
  constructor({filter, header}) {
    this.propertyFilter = filter
    this.header = header
    this.DOM = { el: document.querySelector('.HeroFullWidth') }
    this.DOM.title = this.DOM.el.querySelector('.js-title')
    this.DOM.desc = this.DOM.el.querySelector('.js-desc')
    this.DOM.image = this.DOM.el.querySelector('.js-hero-container')
    this.toggleHeroEvent = this.toggleHero.bind(this)
  }

  create() {
    this.title = SplitTitle(this.DOM.title)
    this.desc = SplitTitle(this.DOM.desc)
    gsap.set([this.DOM.image], { autoAlpha: 0, xPercent: -50 })
    gsap.set([this.header.DOM.el], { yPercent: -100 })

    // on home animation set property filter to hidden
    if (this.propertyFilter) {
      gsap.set(this.propertyFilter.DOM.el, {xPercent: 100})
    }
  

    // split text
    this.onResize()

    if (this.propertyFilter) {
    // event emitter wait for clicks
    this.propertyFilter.on('propertyFilter', this.toggleHeroEvent)
    }


  }

  toggleHero(event) {
   // console.log(event)
  
    if (event == 'close') {
      gsap.to(this.DOM.image, { xPercent: 0})
      this.DOM.image.classList.remove('js-open')
    } else {
      gsap.to(this.DOM.image, { xPercent: -10})
      this.DOM.image.classList.add('js-open')
    }
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.tlHero
      .to(this.title, {
        yPercent: 0,
        duration: 0.8,
        stagger: 0.2
      }, 0)
      .to(this.desc, {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })

    if (this.DOM.image) {

      // animates image in, then animates image by 10 percent so property filter can animate in
      this.tlHero
           .to( this.DOM.image,
              {
                xPercent: 0,
                autoAlpha: 1,
                duration: 2.5,
                ease: 'power4.out'
              },
              0
            )
            .to( this.header.DOM.el, { yPercent: 0,  ease: 'power4.out', duration: 0.4}, 0)
            .addLabel('after')
    }


      if (this.propertyFilter) {
        this.tlHero
            .to( this.DOM.image, { xPercent: -10}, 'after')
            .to( this.propertyFilter.DOM.el, {xPercent: 0}, 'after')
        }
  }



  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  addEventListeners() {}

  onLoad() {
    this.addEventListeners()
    this.animate()
  }
}
