import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

export default class HeroHome {
  constructor({filter, header, wishlist}) {
    this.propertyFilter = filter
    this.wishlist = wishlist
    this.header = header
    this.DOM = { el: document.querySelector('.HeroHome') }
    this.DOM.titles = [...this.DOM.el.querySelectorAll('.js-title .lineChild')]
    this.DOM.buttons = [...this.DOM.el.querySelectorAll('.js-btn')]
    this.DOM.desc = this.DOM.el.querySelector('.js-desc')
    this.DOM.video = this.DOM.el.querySelector('.js-hero-container')
    this.toggleHeroEvent = this.toggleHero.bind(this)
  }

  create() {
  //  this.title = SplitTitle(this.DOM.title)
    this.desc = SplitTitle(this.DOM.desc)
      
    // if (this.wishlist) {
    //   gsap.set(this.wishlist.DOM.wishlistButton, { autoAlpha: 0 })
    // }

    gsap.set(this.DOM.titles, { yPercent: 100})
    gsap.set([this.DOM.video], { autoAlpha: 0, xPercent: -50 })
    gsap.set([this.DOM.buttons], { autoAlpha: 0, yPercent: 20 })
    gsap.set([this.header.DOM.el], { yPercent: -100 })


    // on home animation set property filter to hidden
    if (this.propertyFilter) {
      gsap.set(this.propertyFilter.DOM.el, {xPercent: 100})
    }

    // split text
    this.onResize()

    if (this.propertyFilter) {
      // event emitter wait for clicks
      this.propertyFilter.on('propertyFilter', this.toggleHeroEvent)
      }
  }

  toggleHero(event) {
    if (event == 'close') {
      gsap.to(this.DOM.video, { xPercent: 0})
      this.DOM.video.classList.remove('js-open')
    } else {

      ScrollTrigger.matchMedia({
        // Desktop
        '(min-width: 992px)': () => {
          gsap.to(this.DOM.video, { xPercent: -10})
        
        },
        //mobile
        '(max-width: 991px)': () => {
          gsap.to(this.DOM.video, { xPercent: 0})
        }
      })

  this.DOM.video.classList.add('js-open')

 
    }
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      },
      onComplete: () => {
        // if (this.wishlist) {
        //   gsap.to(this.wishlist.DOM.wishlistButton, { autoAlpha: 1, duration: 0.2, })
        // }
      }
    })

    this.tlHero
      .to(this.DOM.titles, {
        yPercent: 0,
        duration: 0.8,
        stagger: 0.2
      }, 0)
      .to([this.desc, this.DOM.buttons], {
        yPercent: 0,
        duration: 0.8,
        autoAlpha: 1,
        stagger: 0.2
      })



    if (this.DOM.video) {

      // animates video in, then animates video by 10 percent so property filter can animate in
      this.tlHero
           .to( this.DOM.video,
              {
                xPercent: 0,
                autoAlpha: 1,
                duration: 2.5,
                ease: 'power4.out'
              },
              0
            )
            .to( this.header.DOM.el, { yPercent: 0,  ease: 'power4.out', duration: 0.4}, 0)
            .addLabel('after')      
    }

    if (this.propertyFilter) {
      ScrollTrigger.matchMedia({
        // Desktop
        '(min-width: 992px)': () => {
          this.tlHero
          .to( this.DOM.video, { xPercent: -10}, 'after')
          .to( this.propertyFilter.DOM.el, {xPercent: 0}, 'after')
        
        },
        //mobile
        '(max-width: 991px)': () => {
          this.tlHero
          .to( this.DOM.video, { xPercent: 0}, 'after')
          .to( this.propertyFilter.DOM.el, {xPercent: 0}, 'after')
        }
      })
  }
}

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  addEventListeners() {}

  onLoad() {
    this.addEventListeners()
    this.animate()
  }
}