import Timeline from '../Blocks/page/Timeline'
// import Services from '../Blocks/page/Services'
// import Testimonials from '../Blocks/page/Testimonials'
import Gallery from '../Blocks/page/Gallery'
import Accordion from '../Blocks/page/Accordion'
import List from '../Blocks/page/List'
import FAQ from '../Blocks/page/Faq'
import PostDisplay from '../Blocks/Post/PostDisplay'
import Slideshow from '../Blocks/page/Slideshow'
import ImageSection from '../Blocks/page/ImageSection'
import FullWidthImage from '../Blocks/page/FullWidthImage'
// import ContactSection from '../Blocks/page/ContactSection'
import TextProfiles from '../Blocks/page/TextProfiles'
import Button from '../Blocks/page/Button'
import SearchResults from '../Blocks/page/SearchResults'
import MeetTheTeam from '../Blocks/page/MeetTheTeam'
import ImageContentSteps from '../Blocks/page/ImageContentSteps'

export default class BlockManager {
  constructor({ blocks, scroll, wrapper, screenSize }) {
    this.blocks = blocks
    this.scroll = scroll
    this.screenSize = screenSize
    this.scrollWrapper = wrapper
    this.currentBlocks = []
    this.onFirstPageLoad()

    if (typeof wp != 'undefined' && wp.blockEditor) {
      this.gutenberg()
    }
  }

  onFirstPageLoad() {
    //  console.log(this.blocks)
    this.blocks.forEach((block) => {
      this.blockCheck(block)
    })
  }

  blockCheck(block) {
    if (!this.currentBlocks) {
      this.currentBlocks = []
    }

    const className = block.className
    const id = block.id
    // split classnames into an array
    let keys = className.split(' ')
    // filter out any classes that match 'alignfull' or contain 'wp-block'
    keys = keys.filter((cl) => cl !== 'alignfull' || !cl.includes('wp-block'))
    // flatten array
    keys = keys[0]

    switch (keys) {
      case 'PostDisplay':
        const pd = new PostDisplay(id)
        this.currentBlocks.push(pd)
        break
      // case 'Testimonials':
      //   const t = new Testimonials({
      //     scroll: this.scroll,
      //     container: this.scrollWrapper,
      //     id: id
      //   })
      //   this.currentBlocks.push(t)
      //   break
      // case 'ContactSection':
      //   const cs = new ContactSection(id)
      //   this.currentBlocks.push(cs)
      //   break
      case 'Slideshow':
        const s = new Slideshow({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(s)
        break
      case 'Accordion':
        const a = new Accordion({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(a)
        break
      case 'List':
        const l = new List(id)
        this.currentBlocks.push(l)
        break
      case 'FullWidthImage':
        const fwi = new FullWidthImage({
          scroll: this.scroll,
          screenSize: this.screenSize,
          container: this.scrollWrapper,
          id: id
        })
        this.currentBlocks.push(fwi)
        break
      case 'ImageSection':
        const is = new ImageSection({ screenSize: this.screenSize, id: id })
        this.currentBlocks.push(is)
        break
      case 'Gallery':
        const g = new Gallery(id)
        this.currentBlocks.push(g)
        break
      case 'FAQ':
        const faq = new FAQ({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(faq)
        break
      case 'Timeline':
        const tl = new Timeline({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(tl)
        break
      case 'TextProfiles':
        const tp = new TextProfiles({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(tp)
        break
      case 'Button':
        const b = new Button({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(b)
        break
      case 'SearchResults':
        const sr = new SearchResults({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(sr)
        break
      case 'MeetTheTeam':
        const mt = new MeetTheTeam({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(mt)
        break
      case 'ImageContentSteps':
        const ics = new ImageContentSteps({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id
        })
        this.currentBlocks.push(ics)
        break
    }    
  }

  deleteBlocks() {
    if (this.currentBlocks) {
      delete this.currentBlocks
    }
  }

  enterPageTransition(next) {
    // init
    const blocksOnNewPage = [...document.querySelectorAll('[data-block]')]

    blocksOnNewPage.forEach((block) => {
      this.blockCheck(block)
    })
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    this.currentBlocks.forEach((block) => {
      if (block.gutenberg) block.gutenberg()
    })
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    this.currentBlocks.forEach((block) => {
      if (block.onResize) {
        block.onResize(this.screenSize)
      }
      // this.blocks[blocks].onResize(this.screenSize)
    })

    //  console.log('blocks resize')
  }
}
