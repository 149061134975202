import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)


export default class ImageContentSteps {
    constructor ({ scroll, container, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.DOM.titles = [...this.DOM.el.querySelectorAll('.js-title')]
        this.DOM.items = [...this.DOM.el.querySelectorAll('.js-content')]

        this.init()
    }

    onResize () { 
    }

    showVersion(e) {
        const index = this.DOM.titles.indexOf(e.currentTarget)

        // remove other options
        this.DOM.titles.forEach((title) => {

            title.classList.remove('active')
        });

        this.DOM.items.forEach((item) => {
            item.classList.remove('active')
        });

        // select selected
        this.DOM.titles[index].classList.add('active')
        this.DOM.items[index].classList.add('active')
    }

    addEventListeners() {
        this.DOM.titles.forEach((title, index) => title.addEventListener('click', this.showVersion.bind(this)))
    }

    showFirst() {
        this.DOM.titles[0].classList.add('active')
        this.DOM.items[0].classList.add('active')
    }

    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }

    init() {
        this.addEventListeners()
        this.showFirst()
        this.onResize()
    }
}
