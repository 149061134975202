import { gsap, ScrollTrigger } from 'gsap/all'
import { ScrollSmoother } from 'gsap/ScrollSmoother'

console.log(ScrollTrigger, ScrollSmoother)
gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

export default class Accordion {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.items = [
      ...this.DOM.el.querySelectorAll('.accordion__item__master')
    ]

    this.init()
  }

  showList(event) {
    const item = event.target.closest('.accordion__item__master')
    const desc = item.querySelector('.list__item_desc')
    const icon = item.querySelector('.cross-icon')

    //get a perfect slide down
    if (desc.offsetHeight > 0) {
      // console.log('scrollUp')
      desc.style.height = `0px`
      // Rotate Cross to close
      icon.style.transform = 'translateY(-50%) rotate(0)'
    } else {
      // console.log('scrollDown')
      desc.style.height = `${desc.scrollHeight}px`
      // Rotate Cross when open
      icon.style.transform = 'translateY(-50%) rotate(45deg)'
    }

    ScrollSmoother.get().scrollTrigger.refresh()

    // setTimeout(() => {

    //  }, 3000)
  }
  onResize() {}
  addEventListeners() {
    this.DOM.items.forEach((item) => {
      item.addEventListener('click', this.showList.bind(this))
    })
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
  init() {
    this.addEventListeners()
    this.onResize()
  }
}
