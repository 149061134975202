import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

// configure Swiper to use modules
gsap.registerPlugin(ScrollTrigger);
Swiper.use([Navigation, Pagination, Autoplay])

export default class Property {
    constructor({ body, scroll, header}) {
        this.scroll = scroll
        this.header = header
        this.DOM = {el: body }
        this.DOM.pageLink = this.DOM.el.querySelector('.js-page-link')
        this.DOM.slider = this.DOM.el.querySelector('.js-slider')
        this.DOM.prev = this.DOM.slider.querySelector('.swiper-button-prev')
        this.DOM.next = this.DOM.slider.querySelector('.swiper-button-next')
        this.DOM.totalSlides = this.DOM.slider.querySelector('.slider-current-total')
        this.DOM.currentSlide = this.DOM.slider.querySelector('.slider-current-index')
        this.DOM.dataButtons = [...this.DOM.el.querySelectorAll('[data-title]')]
        this.DOM.pageBody = this.DOM.el.querySelector('.single-property__bottom')
        this.DOM.pageColumn = this.DOM.pageBody.querySelector('.single-property__inner-column')
        this.DOM.enquiryBox = this.DOM.pageBody.querySelector('.js-enquiry-box')

        this.init()
        this.addEventListeners()
    }

    reparentModal() {
        // Pin Teamviewer / MINIMAP to page
        const oldParent = document.getElementById('single-property__reparent');
        const newParent = document.getElementById('newparent__teamviewer');
        
        newParent.append(...oldParent.childNodes)

        // modal
        this.DOM.modal = document.querySelector('.single-property-modal-wrapper')
        this.DOM.modalTitle = this.DOM.modal.querySelector('.modal__title')
        this.DOM.modalContent = this.DOM.modal.querySelector('.modal__content')
        this.DOM.modalClose = this.DOM.modal.querySelector('.modal__close')
    }

    pinSidebar() {
        ScrollTrigger.matchMedia({
            // Desktop
            '(min-width: 992px)': () => {
                // pin sidebar to page
                ScrollTrigger.create({
                    endTrigger: this.DOM.pageBody,
                    trigger:  this.DOM.enquiryBox,
                    start: `top-=${this.header.DOM.el.offsetHeight}px top`,
                    end: `bottom+=${this.header.DOM.el.offsetHeight}px center+=${this.header.DOM.el.offsetHeight + (this.header.DOM.el.offsetHeight / 2)}px`,
                    pinReparent: true,
                    pin: true,
                 //   markers: true
                })
            },
            //mobile
            '(max-width: 991px)': () => {
            }
          })
    }

    init() {
        this.runSlider()
        this.pinSidebar()
        this.reparentModal()
    }

    runSlider() {
        const swiper = new Swiper(this.DOM.slider, {
          //  loop: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            },
            navigation: {
                nextEl: this.DOM.next,
                prevEl: this.DOM.prev,
                },
            on: {
                init: (e) => {
                    this.DOM.totalSlides.textContent = e.slides.length
                    this.DOM.currentSlide.textContent = e.activeIndex + 1
                  },
                  slideChange: (e) => {
                  //  console.log(e.activeIndex)
                    this.DOM.currentSlide.textContent = e.activeIndex + 1
                  },
            },

        })

       

    }

    showContent(button) {
        // make a copy of the contact form for re-initialisation
      //  console.log(this.contactForm)
      if (this.DOM.modalContent.classList.contains('white-background')) {
      this.DOM.modalContent.classList.remove('white-background')
      }

        if (!this.contactForm) {
            const oldParent = document.getElementById('single-property__enquiry-form')
            // Create a copy of it
            this.contactForm = oldParent.cloneNode(true);
            this.contactForm.classList.remove('visually-hidden')
        } 

       if (button.dataset.title === 'Contact Us') {
        //   console.log(this.contactForm)
         this.DOM.modalContent.append(this.contactForm)
       } else {
      
        const img = document.createElement("img")
        this.DOM.modalContent.classList.add('white-background')
        img.setAttribute("src", button.src)
        this.DOM.modalContent.appendChild(img)
       }
     
        this.DOM.modalTitle.textContent = button.dataset.title
      
        // show modal add active class
        this.DOM.modal.classList.add('js-active')

    }

  
    showModal(event) {
        this.DOM.el.style.overflow = 'hidden'

        // contact form and floorplan dont contain data-frames aka iframes
        if (!event.currentTarget.getAttribute("data-frame")) {
            return this.showContent(event.currentTarget)
        } 

        let frameInfo = {
            title:event.currentTarget.dataset.title,
            src: event.currentTarget.dataset.frame
        }

        // show modal add active class
        this.DOM.modal.classList.add('js-active')
        
        // add content
        this.DOM.modalTitle.textContent = frameInfo.title

        // create iframe
        const ifrm = document.createElement("iframe")
        ifrm.setAttribute("src", frameInfo.src)
        this.DOM.modalContent.appendChild(ifrm)
    }

    closeModal() {
        this.DOM.el.style.overflow = 'visible'
        this.DOM.modal.classList.remove('js-active')

        //REMOVE old content
        this.DOM.modalTitle.innerHTML = ''
        this.DOM.modalContent.innerHTML = ''
    }

    pageScroll(event) {
        // if link is for scrolling to page section
        if (event.currentTarget.href.includes('#')) {
            event.preventDefault()
            const words = event.currentTarget.href.split('#');
            this.scroll.scrollTo(`#${words[1]}`, true, "top center")
        }
    }

    addEventListeners() {
        this.DOM.dataButtons.forEach(element => {
            element.addEventListener('click', this.showModal.bind(this))
        })

        this.DOM.modalClose.addEventListener('click', this.closeModal.bind(this))

        if (this.DOM.pageLink) {
            this.DOM.pageLink.addEventListener('click', this.pageScroll.bind(this))
        }
    }
  }
  