import { gsap } from 'gsap/all'
import EventEmitter from '../Classes/EventEmitter'

export default class PropertyFilter extends EventEmitter {
  constructor(id) {
    super()
    this.DOM = { el: id }
    this.DOM.filterContent = this.DOM.el.querySelector(
      '.HeroHome__filter-content'
    )
    this.DOM.tab = this.DOM.el.querySelector('.HeroHome__filter-tab')
    this.DOM.toggle = this.DOM.el.querySelector('.js-toggle')

    this.toggleEvent = this.toggleFilter.bind(this)

    gsap.set(this.DOM.el, { y: '-50%' })

    this.activate()
    this.addEventListeners()

    setTimeout(() => {
      this.enquirybot = document.querySelector('.bot-eb-trigger')

      this.enquirybot.addEventListener('click', () => {
        this.toggleFilter()
      })
    }, 2500)
  }

  activate() {
    this.DOM.filterContent.classList.add('js-open')
  }

  closeFilter() {
    // emit to other components that propertyfilter is closed
    this.emit('propertyFilter', 'close')

    gsap.to(this.DOM.el, {
      x: `${this.DOM.filterContent.offsetWidth}px`,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        this.DOM.toggle.classList.remove('js-open')
      }
    })
  }

  openFilter() {
    // emit to other components that propertyfilter is open
    this.emit('propertyFilter', 'open')

    gsap.to(this.DOM.el, {
      x: 0,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        this.DOM.toggle.classList.add('js-open')
      }
    })
  }

  toggleFilter() {
    if (this.DOM.el.classList.contains('js-open')) {
      this.closeFilter()
      this.DOM.el.classList.remove('js-open')
    } else {
      this.openFilter()
      this.DOM.el.classList.add('js-open')
    }
  }

  addEventListeners() {
    this.DOM.tab.addEventListener('click', this.toggleEvent)
  }
}
