// Wishlist to dos

//  add data-button and property ID to each property pages (property block, property restults, property page)
// look for data-button and on add add this to wishlist using gsap flip
// hide wishlist on intial load
// use cookies to save wishlist for 7 days

// on page load
// check for cookies, if so display wishlist
// if we have a wishlist check the id of the wishlist items and if it matches ID of anything on the page update button

// GSAP FLIP CART
// https://codepen.io/hexagoncircle/pen/RwLQLop

import { gsap } from 'gsap/all'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import EventEmitter from '../Classes/EventEmitter'

export default class Wishlist extends EventEmitter {
  constructor({ wishlist, wrapper, body }) {
    super()
    this.DOM = { el: wishlist }
    this.DOM.wrapper = wrapper
    this.body = body
    //  this.DOM.cover = document.querySelector('.page-cover')
    this.DOM.buttons = [...document.querySelectorAll('[data-property]')]
    this.DOM.inner = this.DOM.el.querySelector('.js-wishlist-wrapper')
    this.DOM.count = this.DOM.el.querySelector('.js-wishlist-count')
    this.DOM.wishlistButton = this.DOM.el.querySelector('.btn-wishlist-wrapper')
    this.addToWishlistEvent = this.addToWishlist.bind(this)
    this.searchParams = ''
    this.currentProperties = []
    this.countProperties

    this.init()
  }

  init() {
    this.checkCookies()
    this.addEventListeners()
  }

  checkCookies() {
    // check for cookies, if so display wishlist
    // https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
    const currentStorage = localStorage

    for (const [key] of Object.entries(currentStorage)) {
      if (key.includes('data-property-')) {
        let id = key.split('data-property-')
        id = id[1]

        // push id to stirng for use later
        this.searchParams += id + ','

        // now we have the ID we need to update wishlist button
        this.DOM.buttons.forEach((button) => {
          if (button.dataset.property == id) {
            button.classList.add('js-open')
          }
        })
      }
    }

    // console.log(this.searchParams)
    // if search params is empy do not search
    if (this.searchParams != '') {
      return this.restProperties()
    }
  }

  async restProperties() {
    // 3373, 3431

    // now we need to update wishlist cart

    // we need to use the rest API to check to see if property exists so we can add this
    // /wp-json/wp/v2/property?include=3373,3431

    // grabs all posts
    // console.log(this.searchParams)

    const response = await fetch(
      `/wp-json/wp/v2/property?include=${this.searchParams}`
    )

    //  console.log(`/wp-json/wp/v2/property/${this.searchParams}`)
    const data = await response.json()

    // get acf data for each post
    data.forEach((item) => {
      const obj = {
        id: item.id,
        title: item.title.rendered,
        link: item.link,
        image: item.images[0].url,
        price: item.price_formatted
      }

      this.currentProperties.push(obj)

      return this.currentProperties
    })

    // console.log(this.currentProperties)

    if (this.currentProperties.length) {
      // add each property to the dom
      this.currentProperties.forEach((prop) => {
        this.addToDOM(prop)
      })

      // show wishlist button
      // only show on pages where properties are shown
      if (this.DOM.buttons.length) {
        this.DOM.wishlistButton.classList.add('js-active')
      }
    }

    // remove from local stroage if not found
  }

  storeCookie(id) {
    localStorage.setItem('data-property-' + id, id)

    // add expiration to storrage
  }

  clearCookie(id) {
    localStorage.removeItem('data-property-' + id)
  }

  openWishlist() {
    // send to header
    this.emit('wishlist', 'open')
    //open tab
    this.DOM.el.classList.add('js-active')
    this.DOM.wrapper.classList.add('paged')
    ScrollSmoother.get().paused(true)
    console.log(ScrollSmoother.get())
  }

  closeWishlist() {
    // send to header
    this.emit('wishlist', 'close')
    //close
    this.DOM.el.classList.remove('js-active')
    this.DOM.wrapper.classList.remove('paged')
    ScrollSmoother.get().paused(false)
    console.log(ScrollSmoother.get())
  }

  toggleWishlist(e) {
    console.log(e)

    if (!this.DOM.el.classList.contains('js-active')) {
      //open tab
      this.openWishlist()
    } else {
      //close
      this.closeWishlist()
    }
  }

  removeFromWishlist(property) {
    // find id
    const id = property.parentNode.querySelector(
      '.property-item__wishlist-button'
    ).dataset.property

    // console.log(id)
    // remove matching property from wishlist
    this.DOM.inner.querySelector(`[data-property="${id}"]`).remove()

    // update button
    property.classList.remove('js-open')

    // update count
    this.countProperties = parseInt(this.DOM.count.textContent)
    this.countProperties--
    this.DOM.count.textContent = this.countProperties

    // remove wishlist button if count is less than 1
    if (this.countProperties < 1) {
      this.DOM.wishlistButton.classList.remove('js-active')
      this.DOM.el.classList.remove('js-active')
    }

    //remove cookie
    this.clearCookie(id)
    // console.log(localStorage)
  }

  removeFromInWishlist(event) {
    // find id
    const id = event.currentTarget.dataset.property

    // remove matching property from wishlist
    const items = this.DOM.inner.querySelectorAll('.wishlist-property-item')

    //search through
    items.forEach((item) => {
      if (item.dataset.property == id) {
        item.remove()
      }
    })

    // remove wishlist heart button from selected property
    this.DOM.buttons.forEach((button) => {
      if (button.dataset.property == id) {
        button.classList.remove('js-open')
      }
    })

    // update count
    this.countProperties = parseInt(this.DOM.count.textContent)
    this.countProperties--
    this.DOM.count.textContent = this.countProperties

    // remove wishlist button if count is less than 1
    if (this.countProperties < 1) {
      this.DOM.wishlistButton.classList.remove('js-active')
      this.DOM.el.classList.remove('js-active')
      this.DOM.wrapper.classList.remove('paged')
    }

    //remove cookie
    this.clearCookie(id)
    //  console.log(localStorage)
  }

  addToWishlist(event) {
    const theProperty = event.currentTarget

    // check click event to see if property is already in wishlist
    if (event.currentTarget.classList.contains('js-open')) {
      return this.removeFromWishlist(theProperty)
    }

    let newProperty = {}

    // don't allow user to add more than 10 properties
    if (this.countProperties >= 5) {
      // console.log('yep')
      // console.log(this.countProperties)
      alert(
        'No more than 5 Properties are allowed to be added to wishlist. Please delete properties no longer required and try again'
      )
      return
    }

    if (theProperty.dataset.template === 'single-property.php') {
      // we are on property page store info differently
      // page.php
      // archive-property.php
      //single-property.php

      newProperty = {
        id: theProperty.dataset.property,
        image: document.querySelector('.propertyhive-main-image img').src,
        link: theProperty.dataset.link,
        title: document.querySelector('.property-details__title').textContent,
        price: document.querySelector('.price').textContent
      }
    } else if (theProperty.dataset.template === 'archive-property.php') {
      newProperty = {
        id: theProperty.dataset.property,
        image: theProperty.parentNode.querySelector('.cp__image img').src,
        link: theProperty.parentNode.querySelector('.cp__link').href,
        title: theProperty.parentNode.querySelector('.content-property__title')
          .textContent,
        price: theProperty.parentNode.querySelector('.property-display__price')
          .textContent
      }
    } else {
      // must be on page.php
      newProperty = {
        id: theProperty.dataset.property,
        image: theProperty.parentNode.querySelector('.blog__image img').src,
        link: theProperty.parentNode.querySelector('.property-card__link').href,
        title: theProperty.parentNode.querySelector('.js-title').textContent,
        price: theProperty.parentNode.querySelector('.price').textContent
      }
    }

    this.addToDOM(newProperty)

    // show wishlist button if not shown already
    this.DOM.wishlistButton.classList.contains('js-active')
      ? ''
      : this.DOM.wishlistButton.classList.add('js-active')

    // update button
    event.currentTarget.classList.add('js-open')

    //store cookie
    this.storeCookie(newProperty.id)

    //
  }

  addToDOM(newProperty) {
    // append new Property to wishlist
    const property = document.createElement('DIV')
    property.classList.add('wishlist-property-item')
    property.dataset.property = newProperty.id
    property.innerHTML = this.newProperty(newProperty)

    // add event listener to new property button
    property
      .querySelector('.btn-wishlist-remove')
      .addEventListener('click', this.removeFromInWishlist.bind(this))

    // append to DOM
    this.DOM.inner.appendChild(property)

    // update count
    this.countProperties = parseInt(this.DOM.count.textContent)
    this.countProperties++
    this.DOM.count.textContent = this.countProperties
  }

  newProperty(prop) {
    return `
            <article class="wishlist-property-item__inner">
                <div class="wp__image">
                  ${prop.image ? '<img src="' + prop.image + '">' : ''}
                </div>
                <div class="wp-text">
                    <h6 class='wp-title white'>${prop.title}</h6>
                    <p class='wp-price white'>${prop.price}</p>
                    <a class="wp-btn" href='${prop.link}'>View</a>
                    <button class="btn-wishlist-remove" data-property='${
                      prop.id
                    }'>Remove</button>
                </div>
            </article>
        
    `
  }

  addEventListeners() {
    this.DOM.buttons.forEach((button) => {
      // do a check here if property is in wishlist if not
      button.addEventListener('click', this.addToWishlistEvent)
    })

    this.DOM.wishlistButton.addEventListener(
      'click',
      this.toggleWishlist.bind(this)
    )

    // remove / current properties from wishlist
  }
}
