export default class Fade {
  constructor() {
    this.DOM = {}
    this.DOM.fader = document.getElementById('header-fader')
    this.DOM.anchors = document.getElementsByTagName('a:not(.no-fade)')
    this.fadeOnLinkEvent = this.fadeOnLink.bind(this)
    this.pageShowEvent = this.pageShow.bind(this)

    this.init()
  }


  init() {
    this.fadeInPage()
    this.addEventListeners()
  }

  pageShow() {
    if (!event.persisted) {
      return;
    }
    this.DOM.fader.classList.remove('fade-in')
  }

  fadeInPage() {
    if (!window.AnimationEvent) { return; }
// console.log('fade out')
    this.DOM.fader.classList.add('fade-out')
 }

 fadeOnLink () {
    if (!window.AnimationEvent) { return; }

    for (var idx=0; idx<this.DOM.anchors.length; idx+=1) {
        if (this.DOM.anchors[idx].hostname !== window.location.hostname) {
            continue;
        }
        this.DOM.anchors[idx].addEventListener('click', function(event) { 
            const fader = document.getElementById('header-fader'),
                anchor = event.currentTarget

            // dont run animation if we are just linking to page section
             if (anchor.href.includes ('#')) return

            if (!anchor.dataset.addToShortlist || !anchor.classList.contains('js-team')) {
              const listener = function() {
                window.location = anchor.href
                fader.removeEventListener('animationend', listener)
              }

            
              fader.addEventListener('animationend', listener)
              
              event.preventDefault()
              fader.classList.add('fade-in')
            }
      })
    }
 }

  addEventListeners() {
    document.addEventListener('DOMContentLoaded', this.fadeOnLinkEvent)
    window.addEventListener('pageshow', this.pageShowEvent)
  }
}