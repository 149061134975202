import gsap from "gsap"

export default class Popup {
  constructor({ header }) {
    this.DOM = { el: header.DOM.el }
    // this.DOM.pageBackground = header.DOM.pageBackground
    this.DOM.toggleIcon = header.DOM.toggleIcon
    this.body = header.body
    this.header = header

    // Modal
    this.DOM.modalButton = this.DOM.el.querySelector(".js-valuation-popup")
    this.DOM.modalOuter = document.querySelector(".contact-pop-up")
    this.DOM.modal = document.querySelector(".contact-pop-up-inner")
    this.DOM.modalClose = this.DOM.modal.querySelector(".js-close")
    this.DOM.button = this.DOM.el.querySelector("script")


    this.init()
  }

  init() {
    this.eventListeners()
    gsap.set(this.DOM.modal, { autoAlpha: 0, yPercent: 20})
  }

  toggleModal() {

    if (this.DOM.modal.classList.contains("visible")) {
      // animate out
      gsap.to(this.DOM.modal, {
        yPercent: 20,
        ease: "expo.in",
        duration: 0.4,
        autoAlpha: 0,
        onComplete: () => {
          this.DOM.modal.classList.remove("visible")
          this.DOM.modalOuter.classList.remove("js-opacity")
          this.body.style.overflow = "visible"
        },
      })
    } else {
      // animate in
      gsap.to(this.DOM.modal, {
        yPercent: 0,
        ease: "expo.out",
        duration: 0.8,
        autoAlpha: 1,
        onEnter: () => {
          this.DOM.modalOuter.classList.add("js-opacity")
        },
        onComplete: () => {
          this.DOM.modal.classList.add("visible")
          this.body.style.overflow = "hidden"
        },
      })
    }
  }

  eventListeners() {
    this.DOM.modalButton.addEventListener("click", this.toggleModal.bind(this))
    this.DOM.modalClose.addEventListener("click", this.toggleModal.bind(this))
  }
}
