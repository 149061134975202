import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)

export default class SearchResults {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        //this.DOM.master = this.DOM.el.querySelector('.SearchResults__master')
        this.DOM.filterTitle = this.DOM.el.querySelector('.SearchResults__filter_title')
        this.DOM.filters = this.DOM.el.querySelector('.SearchResults__filters')
        this.DOM.filterX = this.DOM.el.querySelector('.filter-x')
        this.DOM.sortX = this.DOM.el.querySelector('.sort-x')
        this.DOM.sortTitle = this.DOM.el.querySelector('.SearchResults__sort_title')
        this.DOM.sortby = this.DOM.el.querySelector('.SearchResults__sortby')
        this.init()
    }

    showFilters(event) {
        //get a perfect slide down 
        if (this.DOM.filters.offsetHeight > 0) {
            // console.log('scrollUp')
            this.DOM.filters.style.height = `0px`;
            this.DOM.filters.style.opacity = `0`;
            this.DOM.filterX.style.transform = `rotate(0deg)`;
            this.DOM.filters.style.overflow = `hidden`;

        } else {
            // console.log('scrollDown')
            this.DOM.filters.style.height = `${this.DOM.filters.scrollHeight}px`;
            this.DOM.filters.style.opacity = `1`;
            this.DOM.filterX.style.transform = `rotate(45deg)`;
            this.DOM.filters.style.overflow = `visible`;
        }

        if (this.DOM.sortby.offsetHeight > 0) {
            // console.log('scrollUp')
            this.DOM.sortby.style.height = `0px`;
            this.DOM.sortby.style.opacity = `0`;
            this.DOM.sortX.style.transform = `rotate(0deg)`;
            this.DOM.sortby.style.overflow = `hidden`;

        }
    }

    showSort(event) {
        //get a perfect slide down 
        if (this.DOM.filters.offsetHeight > 0) {
            // console.log('scrollUp')
            this.DOM.filters.style.height = `0px`;
            this.DOM.filters.style.opacity = `0`;
            this.DOM.filterX.style.transform = `rotate(0deg)`;
            this.DOM.filters.style.overflow = `hidden`;

        }

        if (this.DOM.sortby.offsetHeight > 0) {
            // console.log('scrollUp')
            this.DOM.sortby.style.height = `0px`;
            this.DOM.sortby.style.opacity = `0`;
            this.DOM.sortX.style.transform = `rotate(0deg)`;
            this.DOM.sortby.style.overflow = `hidden`;

        } else {
            // console.log('scrollDown')
            this.DOM.sortby.style.height = `${this.DOM.sortby.scrollHeight}px`;
            this.DOM.sortby.style.opacity = `1`;
            this.DOM.sortX.style.transform = `rotate(45deg)`;
            this.DOM.sortby.style.overflow = `visible`;
        }
    }

    // Hides Filters & Sort on load
    onLoad(event) {
        this.DOM.filters.style.height = `0px`;
        this.DOM.filters.style.opacity = `0`;
        this.DOM.filters.style.overflow = `hidden`;
        this.DOM.sortby.style.height = `0px`;
        this.DOM.sortby.style.opacity = `0`;
        this.DOM.sortby.style.overflow = `hidden`;
    }

    onResize () { }
    addEventListeners() {
        this.DOM.filterTitle.addEventListener('click', this.showFilters.bind(this))
        this.DOM.sortTitle.addEventListener('click', this.showSort.bind(this))
    }
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    init() {
        this.addEventListeners()
        this.onResize()
        this.onLoad()
    }
}

jQuery(document).ready(function() {
	jQuery('.property-search-form').on('ph:toggleSearchDepartment', function(event, selectedDepartment)
	{
		if ( selectedDepartment == 'residential-sales' )
        {
            jQuery(this).attr('action', '/buy/');
        }
        else
        {
            jQuery(this).attr('action', '/rent/');
        }
	});
});