import MobileMenu from './MobileMenu'
import gsap from 'gsap'

export default class Header {
  constructor({ scroll, container, propertyFilter, wishlist }) {
    this.body = document.querySelector("body")
    this.propertyFilter = propertyFilter
    this.wishlist = wishlist
    this.DOM = { el: document.querySelector('header') }
    this.DOM.headerType = this.DOM.el.querySelector('.header-type')
    this.DOM.toggleIcon = this.DOM.el.querySelector('.menu_toggle')
    this.DOM.mobileMenu = this.DOM.el.querySelector('.js-mobile-menu')
 //   this.DOM.pageBackground = document.querySelector('.page-cover')
    this.DOM.fullScreenNav = this.DOM.el.querySelector('.fullscreen-nav-js')
    this.DOM.revealActive = this.DOM.el.classList.contains(
      'header__activate_scrolling'
    )
    this.DOM.menuItem = [
      ...document.querySelectorAll('.menu-item-has-children a:not(.sub-menu a)')
    ]
    this.DOM.menuItemHasChildren = [
      ...document.querySelectorAll('.menu-item-has-children')
    ]

    this.screenLarge = window.matchMedia('(min-width: 992px)')
    this.scrollUp = 'js-scroll-up'
    this.scrollDown = 'js-scroll-down'
    this.lastScroll = 0
    this.windowX = null
    this.winY = null

    this.DOM.subMenuToggles = [...document.querySelectorAll('.sm-dropdown')]
    this.DOM.headerTabs = [...document.querySelectorAll('.Sub-menu')]
    this.DOM.masterTab = this.DOM.el.querySelector('.master-header-tab')

    this.toggleMenuEvent = this.showHeaderTabs.bind(this)
    this.currentheaderTab
    this.toggleWishlistEvent = this.toggleWishlist.bind(this)

    this.timelines = {}

    this.mobileMenu = new MobileMenu({
      menu: this.DOM.mobileMenu,
      icon: this.DOM.toggleIcon
    })
    
    this.init()
  }

  setTabs() {
    // Set all tabs initial state
    this.DOM.headerTabs.forEach((tab) => {
      tab.imageBlock = tab.querySelector('.Sub-menu__image')
      tab.image = tab.querySelector('.Sub-menu__image img')
      tab.mask = tab.querySelector('.Sub-menu__image--mask')
      tab.currentColumn = tab.querySelector('.Sub-menu__inner')

      gsap.set(tab.imageBlock, { yPercent: 101 })
      gsap.set(tab.image, { scale: 1.5 })
      gsap.set(tab.mask, { yPercent: -101 })
      gsap.set(tab.currentColumn, { yPercent: 10, autoAlpha: 0 })
    })
  }

  hideTab(tab) {
    tab.imageBlock = tab.querySelector('.Sub-menu__image')
    tab.image = tab.querySelector('.Sub-menu__image img')
    tab.mask = tab.querySelector('.Sub-menu__image--mask')
    tab.currentColumn = tab.querySelector('.Sub-menu__inner')

    gsap.set(tab.imageBlock, { yPercent: 101 })
    gsap.set(tab.image, { scale: 1.5 })
    gsap.set(tab.mask, { yPercent: -101 })
    gsap.set(tab.currentColumn, { yPercent: 10, autoAlpha: 0 })
  }

  init() {
    this.setTabs()
    this.eventListeners()



    if (this.wishlist) {
      // event emitter wait for clicks
      this.wishlist.on('wishlist', this.toggleWishlistEvent)
      }
  }

  toggleWishlist(event) {
    // wishlist open
    if (event == 'open') {
    // check megamenu if open close
    if (this.DOM.masterTab.classList.contains('tab-open')) {
      // tell filter to close
      if (this.propertyFilter) {
        this.propertyFilter.closeFilter()
      }
      this.closeMegamenu()
    }
    } else {
      // close
      if (this.propertyFilter) {
        this.propertyFilter.openFilter()
      }
     
    }
  }

  closeMegamenu() {
    console.log('close mrga menu')
    const currentMenu = this.DOM.el.querySelector('.Sub-menu.tab-open')
 
      // TAB Variables
      const imageBlock = currentMenu.querySelector('.Sub-menu__image')
      const image = currentMenu.querySelector('.Sub-menu__image img')
      const mask = currentMenu.querySelector('.Sub-menu__image--mask')
      const currentColumn = currentMenu.querySelector(
        '.Sub-menu__inner'
      )

    this.timelines.closeMegamenu = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.25,
      },
        onComplete: () => {
          // remove all rotated icons
          this.DOM.subMenuToggles.forEach((icon) => {
            const item = icon.parentNode.querySelector('a')
            item.classList.remove('js-active')
            icon.classList.remove('js-open')
          })
          currentMenu.classList.remove('tab-open')
          this.DOM.masterTab.classList.remove('tab-open')
        }
    })

    // CLOSE MASTER TAB from clicking wishlist
    this.timelines.closeMegamenu
      .to( this.DOM.masterTab, { height: 0})
      .to([image], { scale: 1.5 }, 0)
      .to([imageBlock], { yPercent: 100 }, 0)
      .to([mask], { yPercent: -100 }, 0)
      .to(currentColumn, { yPercent: 10, autoAlpha: 0 }, '<50%')

  }

  onResize() {
    if (this.screenLarge.matches) {
     // this.DOM.pageBackground.classList.remove('page-cover-opacity')
    }

    // if (this.DOM.masterTab.classList.contains('tab-open')) {

    //   // re-calculate height
    //   if ( this.currentheaderTab != null) {
    //     console.log(this.DOM.masterTab.offsetHeight)
    //     this.DOM.masterTab.offsetHeight = this.currentheaderTab.offsetHeight
    //   }

    // }
  }


  showHeaderTabs(event) {
    
    if (this.wishlist) {
      this.wishlist.closeWishlist()
    }
    // OBJECTS
    const icon = event.currentTarget
    const item = event.currentTarget.parentNode.querySelector('a')

    icon.disabled = true
    const currentheaderSelector = icon.dataset.menu

    // check to find matching sub-menu
    this.DOM.headerTabs.forEach((tab) => {
      if (tab.dataset.menu === currentheaderSelector)
        return (this.currentheaderTab = tab)
    })

    // TAB Variables
    const imageBlock = this.currentheaderTab.querySelector('.Sub-menu__image')
    const image = this.currentheaderTab.querySelector('.Sub-menu__image img')
    const mask = this.currentheaderTab.querySelector('.Sub-menu__image--mask')
    const currentColumn = this.currentheaderTab.querySelector(
      '.Sub-menu__inner'
    )

    // MASTER HEADER TAB
    // Show if no tabs are active

    if (!this.DOM.masterTab.classList.contains('tab-open')) {
      // tell filter to close
        if (this.propertyFilter) {
          this.propertyFilter.closeFilter()
        }

      //open master dropdown
      this.timelines.showMaster = gsap.timeline({
        defaults: {
          ease: 'power4.out',
          duration: 0.25,
        },
        onComplete: () => {
          this.DOM.masterTab.classList.add('tab-open')
          icon.disabled = false
        }
      })

      this.timelines.showMaster.to(this.DOM.masterTab, {
        height: this.currentheaderTab.offsetHeight
      })
    }

    // if the current target already contains tab-open remove master tab

    if (icon.classList.contains('js-open')) {
      // tell filter to open
      if (this.propertyFilter) {
        this.propertyFilter.openFilter()
      }

      this.timelines.oldTabExit = gsap.timeline({
        defaults: {
          ease: 'expo.out',
          duration: 0.25,
        },
          onComplete: () => {
            icon.disabled = false
            icon.classList.remove('js-open')
            item.classList.remove('js-active')
    
            this.currentheaderTab.classList.remove('tab-open')
            this.DOM.masterTab.classList.remove('tab-open')
          }
      })

      // CLOSE MASTER TAB
      this.timelines.oldTabExit
        .to( this.DOM.masterTab, { height: 0})
        .to(currentColumn, { yPercent: 10, autoAlpha: 0 }, '<50%')
        .to([imageBlock], { yPercent: 100 })
        .to([mask], { yPercent: -100 })
        .to([image], { scale: 1.5 })

    } else {

   

      // remove old content
      this.DOM.headerTabs.forEach((tab) => {
        tab.classList.remove('tab-open')
      })

      // remove all rotated icons
      this.DOM.subMenuToggles.forEach((iconic) => {
        const item = iconic.parentNode.querySelector('a')
        item.classList.remove('js-active')
        iconic.classList.remove('js-open')
      })

      // ADD IN
      icon.classList.add('js-open')
      item.classList.add('js-active')

    
      this.currentheaderTab.classList.add('tab-open')

      this.timelines.showNewTab = gsap.timeline({
          defaults: {
            ease: 'expo.out',
            duration: 0.25,
            stagger: 0.1
          },
          onStart: () => {
        
            //re-calc height
            this.DOM.masterTab.style.height = `${this.currentheaderTab.offsetHeight}px`
          },
          onComplete: () => {
            icon.disabled = false
 
            // here I want to make suree all other tabs are hidden
            this.DOM.headerTabs.forEach((tab) => {
              if (!tab.classList.contains('tab-open')) {
               this.hideTab(tab)
              }
            })
          }
      })

      this.timelines.showNewTab
              .to([imageBlock], { yPercent: 0 })
              .to(currentColumn, { yPercent: 0, autoAlpha: 1 })
              .to([mask], { yPercent: 0 }, 0)
              .to([image], { duration: 1, scale: 1 }, '<50%')
 
    }
  }

  eventListeners() {
    this.DOM.subMenuToggles.forEach((toggle) => {
      toggle.addEventListener('click', this.toggleMenuEvent)
    })

    if (this.DOM.toggleIcon) this.DOM.toggleIcon.addEventListener('click', this.toggleMenu.bind(this))
    
    window.addEventListener('resize', this.onResize.bind(this))

  }

  toggleMenu(e) {
    this.DOM.toggleIcon.classList.toggle('toggle_on')
    this.mobileMenu.toggle()
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll('.arrow-down')]
    dropdwns.forEach((toggle) => toggle.classList.remove('js-open'))
  }

  scrolling(e) {
    // WHEN YOU SCROLL DOWN HIDE HEADER, REVEAL WHEN SCROLLING UP

    // console.log(this)
    // accessed through index.js hence why we need to use 'header.'

    if (this.header.DOM.revealActive != null) {
      this.header.currentScroll = window.pageYOffset

      if (this.header.currentScroll === 0) {
        this.header.DOM.el.classList.remove(this.header.scrollUp)
        return
      }

      if (
        this.header.currentScroll > this.header.lastScroll &&
        !this.header.DOM.el.classList.contains(this.header.scrollDown)
      ) {
        // DOWN
        if (this.header.DOM.fullScreenNav) {
          this.header.DOM.fullScreenNav.classList.contains('opacity-full')
            ? ''
            : ''
        } else {
          this.header.DOM.el.classList.remove(this.header.scrollUp)
          this.header.DOM.el.classList.add(this.header.scrollDown)

          // CLOSE ALL SUBMENUS on scroll down
          if (this.header.DOM.subMenus) {
            this.header.DOM.subMenus.forEach((menu) => {
              menu.classList.remove('active-menu')
              menu.classList.remove('open-menu')
            })
          }

          this.header.closeDropdowns()
        }

        // this.header.DOM.toggleIcon.classList.remove('toggle_on')
      } else if (
        this.header.currentScroll < this.header.lastScroll &&
        this.header.DOM.el.classList.contains(this.header.scrollDown)
      ) {
        // UP
        this.header.DOM.el.classList.remove(this.header.scrollDown)
        this.header.DOM.el.classList.add(this.header.scrollUp)
      }
      this.header.lastScroll = this.header.currentScroll
    }
  }
}
