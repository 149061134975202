import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, DrawSVGPlugin, SplitText } from 'gsap/all'
gsap.registerPlugin(DrawSVGPlugin, SplitText)

export default class HeroText {
  constructor() {
    this.DOM = { el: document.querySelector('.HeroText') }
    this.DOM.title = this.DOM.el.querySelector('.js-title')
    this.DOM.desc = this.DOM.el.querySelector('.js-desc')
  }

  create() {
    this.title = SplitTitle(this.DOM.title)
    this.desc = SplitTitle(this.DOM.desc)

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: 'expo.out',
        duration: 0.8
      }
    })

    this.tlHero
      .to([this.title, this.desc], {
        yPercent: 0,
        duration: 0.8,
        stagger: '0.2'
      })
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
  }
}
